/* @font-face kit by Fonts2u (https://fonts2u.com) */
@font-face {
  font-family: 'Source Sans Pro';
  src:
    url('SourceSansPro-Regular.eot?') format('eot'),
    url('SourceSansPro-Regular.woff') format('woff'),
    url('SourceSansPro-Regular.ttf') format('truetype'),
    url('SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
